/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";

@import "animate.css";

@import "app";
@import "common";
@import "extra";
@import "authentication";
@import "responsive";

@import "icons/font-awesome/css/font-awesome.min.css";
@import "icons/simple-line-icons/css/simple-line-icons.css";
@import "icons/themify-icons/themify-icons.css";


@font-face {
    font-family: "FuturaLTW01-Medium";
    src: url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.eot");
    src: url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.woff") format("woff"), url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.svg#FuturaLTW01-Medium") format("svg");
}

@font-face {
    font-family: "FuturaLTW01-Light";
    src: url("/assets/fonts/Futura Lt Light.ttf"),
        url("/assets/fonts/Futura Lt Light.ttf") format("truetype");
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';